<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">{{mapCategory[Category]}}</h1>
            </div>
        </div>

        <div class="row ">

            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">
                        <div v-show="checkAcl(Category+'Upload')">
                            <button type="button" class="btn btn-primary btn-with-icon" @click="FileUpload=null;showUpload=true"><i class="ion-android-upload"></i>Upload</button>

                        </div>
                        <div class="mt-3">
                            <table class="table table-hover table-bordered" id="datatable">
                                <thead>
                                    <tr>
                                        <th id="columnName">Name</th>
                                        <th>File Type</th>
                                        <th>Downloaded</th>
                                        <th>Last Update</th>
                                        <th>Updated By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item,filename in data" :key="filename">
                                        <td>
                                            <a href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip" data-bs-placement="top"
                                               @click="editFile(filename)"
                                               title="Edit File"
                                               v-show="checkAcl(Category+'Upload')">
                                                {{filename}}
                                            </a>
                                            <span v-show="!checkAcl(Category+'Upload')">{{filename}}</span>

                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" v-for="type in item.FileList" :key="type.FileId" @click="downloadResource(type.FileId,filename)">
                                                <MDBTooltip v-model="type.ToolTip" tag="a" :maxWidth=300>
                                                    <template #reference>

                                                        <img :src="require(`@/assets/img/app/${getFileIcon(type.FileExt)}`)" style="width:32px;" />


                                                    </template>
                                                    <template #tip>
                                                        <div class="text-start">
                                                            <div>File: <b>{{type.FileName}}</b></div>
                                                            <div>Uploaded Date: <b>{{formatDate(type.UploadedDate)}}</b></div>
                                                            <div>Updated Date: <b>{{formatDate(type.Updated)}}</b></div>
                                                            <div>Last Updated By: <b>{{type.UpdatedBy}}</b></div>
                                                            <div>FileType:<img :src="require(`@/assets/img/app/${getFileIcon(type.FileExt)}`)" v-show="type.FileExt!='URL'" /> <b>{{type.FileType}}</b></div>
                                                            <div v-show="type.FileExt=='URL'">Url: <b>{{type.FileName}}</b></div>
                                                            <div v-show="type.FileExt!='URL'">Downloaded: <b>{{type.Downloaded}}</b></div>
                                                            <div v-show="type.Note!=''">Note: <i>{{type.Note}}</i></div>

                                                        </div>
                                                    </template>
                                                </MDBTooltip>
                                            </a>
                                        </td>
                                        <td>{{item.Downloaded}}</td>
                                        <td>{{formatDate(item.Updated)}}</td>
                                        <td>{{item.UpdatedBy}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <MDBModal v-model="showEditFile" size="" staticBackdrop>
            <MDBModalHeader>
                <MDBModalTitle> Edit File {{OldFileName}} </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow id="editFileForm" tag="form" class="g-3">
                    <MDBCol md="12">
                        <MDBInput label="FileName"
                                  v-model="FileName"
                                  id="FileName"
                                  invalidFeedback="test"
                                  validFeedback=""
                                  required />

                    </MDBCol>

                    <MDBCol md="12" class="text-center">
                        ========== List of types ==========
                    </MDBCol>


                    <MDBCol md="12" v-for="type in Notes" :key="type.FileExt">
                        <div>
                            <span><img :src="require(`@/assets/img/app/${getFileIcon(type.FileExt)}`)" style="width:32px" /> </span>
                            <span>{{getFileType(type.FileExt)}}</span>
                            <span style="margin-left:10px;float:right">
                                <a href="javascript:;" @click="removeFileType(type.FileId)" title="Delete file" class="ml-6">
                                    <img :src="require(`@/assets/img/app/delete.png`)" />
                                </a>
                            </span>
                        </div>
                        <div>Uploaded Date: <b>{{formatDate(type.UploadedDate)}}</b></div>
                        <div>Updated Date: <b>{{formatDate(type.Updated)}}</b></div>
                        <div>Last Updated By: <b>{{type.UpdatedBy}}</b></div>
                        <div>Downloaded: <b>{{type.Downloaded}}</b></div>
                        <MDBTextarea label="File Note" v-model="type.Note" class="mt-3" />
                        <hr />
                    </MDBCol>


                </MDBRow>

            </MDBModalBody>
            <MDBModalFooter>
                <button type="button" class="btn btn-primary" @click="doSaveFile"><i class="ion-checkmark"></i> Save</button>
                <button type="button" class="btn btn-danger" @click="showEditFile=false"><i class="ion-close"></i> Cancel</button>
            </MDBModalFooter>
        </MDBModal>

        <MDBModal v-model="showUpload" staticBackdrop>
            <MDBModalHeader>
                <MDBModalTitle> Upload {{mapCategory[Category]}} </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow tag="form" class="g-3">
                    <MDBCol md="12">
                        File Name:
                        <button type="button" class="btn btn-xs dropdown-toggle"
                                data-bs-toggle="dropdown">
                            {{FileName}}<span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu">
                            <li>
                                <a class="dropdown-item" href="javascript:void(0);" @click="CreateNewFileName">==== Pick a new FileName ====</a>
                            </li>
                            <li v-for="name in FileNameExisted" :key="name">
                                <a class="dropdown-item" href="javascript:void(0);" @click="FileName=name">{{name}}</a>
                            </li>
                        </ul>

                    </MDBCol>
                    <MDBCol md="12">
                        <MDBInput label="Link"
                                  v-model="Link"
                                  id="Link"
                                  invalidFeedback="test"
                                  validFeedback="" />
                    </MDBCol>
                    <MDBCol md="12">
                        <button type="button" class="btn btn-primary btn-with-icon" @click="SelectFileUpload(event)"><i class="ion-document"></i>Select many files</button>
                        <div class="mt-3" id="uploadFileInfo">

                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
                <button type="button" class="btn btn-primary" @click="doUpload"><i class="ion-android-upload"></i> Upload</button>
                <button type="button" class="btn btn-danger" @click="showUpload=false"><i class="ion-close"></i> Cancel</button>
            </MDBModalFooter>
        </MDBModal>

        <MDBModal v-model="showCreateNewFileName" staticBackdrop>
            <MDBModalHeader>
                <MDBModalTitle> New FileName</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow tag="form" class="g-3">                  
                    <MDBCol md="12">
                        <MDBInput label="New File Name"
                                  v-model="FileName"
                                  id="FileName"
                                  invalidFeedback="test"
                                  validFeedback="" />
                    </MDBCol>               
                </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
                <button type="button" class="btn btn-primary" @click="doCreateNewFileName"><i class="ion-android-upload"></i> Create</button>
                <button type="button" class="btn btn-danger" @click="showCreateNewFileName=false"><i class="ion-close"></i> Cancel</button>
            </MDBModalFooter>
        </MDBModal>

    </div>
</template>
<style>
    .excel-file {
        background: url('~@/assets/img/app/excel.png') no-repeat;
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button {
        color: white !important;
    }
</style>
<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    //import "datatables.net-buttons/js/dataTables.buttons.js"
    //import "datatables.net-buttons/js/buttons.colVis.js"
    //import "datatables.net-buttons/js/buttons.flash.js"
    //import "datatables.net-buttons/js/buttons.html5.js"
    //import "datatables.net-buttons/js/buttons.print.js"
    import $ from 'jquery';
    import 'datatables.net-responsive'


    import {
        //MDBBtn,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBRow, MDBCol, MDBInput, MDBTextarea, 
        MDBModalFooter,
        MDBTooltip
    } from 'mdb-vue-ui-kit';

    import c from '@/mylib/common'
    import { mapState,mapActions } from 'vuex'
    //import { map } from "core-js/library/core/dict";
    export default {
        name: `Resource`,
        data: function () {
            return {
                LayoutModel: {},
                Category:'',
                data: [],
                dataTable:null,

                allowedType:".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt",
                mapFileType: { ".xlsx": "Microsof Excel", ".xls": "Microsof Excel", ".doc": "Microsof Word", ".docx": "Microsof Word", ".pdf": "PDF", ".ppt": "Microsof PowerPoint", ".pptx": "Microsof PowerPoint", ".txt": "Text Document", "URL": "Link", ".jpg": "Image", ".png": "Image", ".jpeg": "Image" },
                mapFileIcon: { ".xlsx": "excel.png", ".xls": "excel.png", ".doc": "word.png", ".docx": "word.png", ".pdf": "pdf.png", ".ppt": "ppt.png", ".pptx": "ppt.png", ".txt": "notepad.png", "URL": "link.png", ".jpg": "images.png", ".png": "images.png", ".jpeg": "images.png" },
                mapCategory: { BangGia: "Price all (Bảng Giá)", CatalogRuou: "Catalog Wine", CatalogSake: "Catalog Sake", Hosonangluc: "Presentation (Hồ sơ năng lực)", Training: "Training", TastingNote: "Tasting Notes", MarketingEvent: "Marketing Event" },
                //========= Upload Popup ===========
                showUpload: false,
                FileUpload: null,
                showCreateNewFileName: false,
                
                //========= Edit Popup ===========
                FileNameExisted: {},
                OldFileName: '',
                FileName: '',
                Notes: {},
                Link: '',
                LinkNote:'',
                ckLink:false,
                showEditFile: false,
            }
        },
        

        components: {
            
            //MDBBtn,
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBRow, MDBCol, MDBInput, MDBTextarea, 
            MDBModalFooter,
            MDBTooltip
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
            username: state => state.session.username,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',                
                'call': 'api/call',
                'callDownload': 'api/callDownload'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.Category = this.$route.meta["resource"];
                this.getAll();                
            },
            CreateNewFileName() {                
                this.showCreateNewFileName = true;
            },
            doCreateNewFileName() {
                if (!this.validFileName()) {
                    this.showToast({ isShow: true, title: '', content: 'Form is not valid!', type: 'error' });
                    return;
                }
                this.FileNameExisted[this.FileName.trim().toLowerCase()] = this.FileName;
                this.showCreateNewFileName = false;
            },
            formatDate(dstr,notime) {
                let d = new Date(dstr);
                if (d == "Invalid Date") return "";
                return c.formatDate(d, notime);
            },
            formatNumber(n) {                
                return c.formatNumber(n);
            },
            validFileName() {
                return this.validTextField(this.FileName.trim().toLowerCase(), 'FileName', true, null, null, 2, 50, this.FileNameExisted);
            },
            validFileNameNoDup() {
                return this.validTextField(this.FileName, 'FileName', true, null,null, 2, 50);
            },
            validLink() {
                return this.validTextField(this.Link, 'Link', false, /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, 'Invalid URL');
            },
            validTextField(value, id, required, regex, regexMessage, minlen, maxlen, NameExisted, editedId) {
                if (!id || id == '') return;
                let error = "";
                minlen = minlen || 0;
                maxlen = maxlen || 1000;
                editedId = editedId || 0;
                const checkedvalue = value.trim();
                const isCheckRequire = required || (!required && checkedvalue.length > 0);

                if (required && checkedvalue == '') {
                    error = "Please input this field!";
                }
                //check name existed
                else if (NameExisted != null && NameExisted != undefined &&
                    (NameExisted[checkedvalue] != undefined && editedId != NameExisted[checkedvalue] || (Array.isArray(NameExisted) && NameExisted.includes(checkedvalue)))
                ) {
                    error = id + " is duplicated!";
                }
                //check pattern
                else if (isCheckRequire && regex != null && regex != undefined && !regex.test(checkedvalue))
                    error = regexMessage || `Invalid ${id}!`;
                //check len

                else if (isCheckRequire && (checkedvalue.length < minlen)) {
                    error = `Please input at least ${minlen} characters!`;
                }
                else if (checkedvalue.length > maxlen) {
                    error = `Please do not exceed ${maxlen} characters!`;
                }

                //return value
                if (error == '') {
                    if ($('#' + id).length > 0) {
                        $('#' + id).removeClass('is-invalid');
                        $('#' + id).addClass('is-valid');
                    }
                    return true;
                } else {
                    console.log($('#' + id),error);
                    if ($('#' + id).length > 0) {
                        $('#' + id + '~.invalid-feedback')[0].innerHTML = error;
                        $('#' + id).addClass('is-invalid');
                        $('#' + id).removeClass('is-valid');
                    }
                    $('#' + id).focus();
                    return false;
                }
            },
            async downloadResource(fileid,fileName) {

                const file = this.data[fileName]["FileList"][fileid];
                if (file.FileExt == 'URL') {
                    window.open(file.FileName);
                    return;
                }
                this.loading(true);
               
                if (!file) {
                    this.showToast({
                        isShow: true, title: '', content: `File not found!`, type: 'error'
                    });
                    return;
                }
                const rs = await this.call({ Action: 'Resource/Download' + this.Category, Object: { FileID: file.FileId } })
                this.loading(false);
                this.error = rs.Error;
                
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    c.SaveFile(rs.Data, "application/octet-stream", file.FileName);
                    this.data[fileName]["Downloaded"]++;
                    this.data[fileName]["FileList"][file.FileId]["Downloaded"]++;
                }
            },
            editFile(fileName) {
                this.OldFileName = fileName;
                this.FileName = fileName;

                this.Notes = {};
                Object.keys(this.data[fileName]["FileList"]).forEach(fileid => {
                    var type = this.data[fileName]["FileList"][fileid];                    
                    this.Notes[fileid] = Object.assign({}, type);
                });

                this.showEditFile = true;
            },
            removeFileType(fileid) {                
                delete this.Notes[fileid];
            },
            async doSaveFile() {
                //======= validate =========
                //$('#editForm').addClass('was-validated');                
                if (!this.validFileNameNoDup() || (this.ckLink && !this.validLink())) {
                    this.showToast({ isShow: true, title: '', content: 'Form is not valid!', type: 'error' });
                    return;
                }

               
                            
                var notes = {};
                Object.keys(this.Notes).forEach((fileid) => {
                    var type = this.Notes[fileid];
                    notes[fileid] = type.Note;
                   
                    //update if changed
                    if (this.data[this.OldFileName]["FileList"][fileid] == undefined
                        || notes[fileid] != this.data[this.OldFileName]["FileList"][fileid].Note
                    ) {
                        this.Notes[fileid].Updated = this.formatDate(new Date());
                        this.Notes[fileid].UpdatedBy = this.username;
                    }
                });

                
                //======== do submit =========
                this.loading(true);
                const rs = await this.call({
                    Action: 'Resource/Save' + this.Category, Object: {
                        OldName: this.OldFileName,
                        NewName: this.FileName,
                        Notes: notes
                    }
                });
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                                      
                } else {
                    delete this.data[this.OldFileName];
                    delete this.FileNameExisted[this.OldFileName];
                    this.data[this.FileName] = {
                        "FileList": {},
                        "Downloaded": 0,
                        "Updated": "",
                        "UpdatedBy": ""
                    };
                    this.FileNameExisted[this.FileName.toLowerCase()] = this.FileName;

                    const typeKeys = Object.keys(this.Notes);
                    let downloaded = 0;
                    if (typeKeys.length > 0) {                        
                        for (let i = 0; i < typeKeys.length; i++) {
                            console.log(this.FileName,typeKeys[i],this.data[this.FileName], this.Notes[typeKeys[i]].Updated, this.data[this.FileName]["Updated"] < this.Notes[typeKeys[i]].Updated)
                            //latest updated:
                            if (this.data[this.FileName]["Updated"] == "" || this.data[this.FileName]["Updated"] < this.Notes[typeKeys[i]].Updated) {
                                this.data[this.FileName]["Updated"] = this.Notes[typeKeys[i]].Updated;
                                this.data[this.FileName]["UpdatedBy"] = this.Notes[typeKeys[i]].UpdatedBy;
                            }
                            if (this.Notes[typeKeys[i]].FileExt == "URL") continue;
                            downloaded += this.Notes[typeKeys[i]].Downloaded;
                            
                        }

                        this.data[this.FileName]["FileList"] = this.Notes;
                        this.data[this.FileName]["Downloaded"] = downloaded;
                        
                    }

                    this.showToast({
                        isShow: true, title: '', content: `Save <b>${this.FileName}</b> success.`, type: 'success'
                    });

                    if (this.dataTable != null) {
                        this.dataTable.destroy();
                    }
                    setTimeout(this.doTable, 200);
                    this.showEditFile = false;
                }
            },
            async getAll() {
                this.loading(true);
                if (this.dataTable != null) {
                    this.dataTable.destroy();
                }
                const rs = await this.call({ Action: 'Resource/List'+this.Category })
                
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    this.loading(false);
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }

                } else {                    
                    
                    this.data = {};
                    for (let i = 0; i < rs.Data.length; i++) {
                        if (!this.data[rs.Data[i].FileName]) {
                            this.data[rs.Data[i].FileName] = {
                                "FileList": {},
                                "Downloaded": 0,
                                "Updated": "",
                                "UpdatedBy":""
                            };
                            this.FileNameExisted[rs.Data[i].FileName.trim().toLowerCase()] = rs.Data[i].FileName;
                        }

                        var tmp = rs.Data[i].CloudPath.split("/");
                        var filename = tmp[tmp.length - 1];
                        this.data[rs.Data[i].FileName]["FileList"][rs.Data[i].Id]={
                            FileId: rs.Data[i].Id,
                            FileExt: rs.Data[i].FileType,
                            FileType: this.getFileType([rs.Data[i].FileType]),
                            FileName: rs.Data[i].FileType == "URL" ? rs.Data[i].CloudPath:filename,
                            UpdatedBy: rs.Data[i].UpdatedBy,
                            Updated: rs.Data[i].Updated,
                            UploadedDate: rs.Data[i].UploadedDate,
                            Downloaded: rs.Data[i].Downloaded,
                            Note: rs.Data[i].Note || "",                            
                            ToolTip:false,
                        };                        
                        this.data[rs.Data[i].FileName]["Downloaded"] += rs.Data[i].Downloaded;
                        //latest updated:
                        if (this.data[rs.Data[i].FileName]["Updated"] == "" || this.data[rs.Data[i].FileName]["Updated"] < rs.Data[i].Updated) {
                            this.data[rs.Data[i].FileName]["Updated"] = rs.Data[i].Updated;
                            this.data[rs.Data[i].FileName]["UpdatedBy"] = rs.Data[i].UpdatedBy;
                        }
                    }
                    setTimeout(this.doTable, 200);
                    
                }
            },
            
            doTable() {
                const _this = this;
                const order = [];
                if (this.Category == "Training")
                    order.push([3, "desc"]);
                else order.push([3, "desc"]);
                _this.dataTable = $('#datatable').DataTable({
                    responsive: true,
                    "order":order
                    
                });
                this.loading(false);
            },
            getFileIcon(fileext) {
                return this.mapFileIcon[fileext] ? this.mapFileIcon[fileext] : "3d_file.png";
            },
            getFileType(fileext) {
                return this.mapFileType[fileext] ? this.mapFileType[fileext] : "Unsupport File";
            },
            SelectFileUpload() {
                var el = window._protected_reference = document.createElement("INPUT");
                el.type = "file";
                el.accept = this.allowedType;
                //el.accept = "image/*";
                el.multiple = "multiple"; // remove to have a single file selection

                // (cancel will not trigger 'change')
                var _this = this;
                el.addEventListener('change', function () {
                    // access el.files[] to do something with it (test its length!)

                    // add first image, if available
                    document.getElementById('uploadFileInfo').innerHTML = '';
                    _this.FileUpload = [];
                    for (let i = 0; i < el.files.length;i++) {

                        _this.FileUpload.push(el.files[i]);
                        document.getElementById('uploadFileInfo').innerHTML += el.files[i].name+'<br/>';
                    }

                });

                el.click();
            },
            async doUpload() {
                if (this.FileName=="") {
                    this.showToast({
                        isShow: true, title: '', content: ` Please Input A FileName.`, type: 'error'
                    });
                    return;
                }
                else if ((this.FileUpload == null && this.Link == "")) {

                    this.showModal({ isShow: true, title: 'Error', content: "Please select files upload or input a LINK", type: 'error' });
                    return;
                }
                this.showUpload = false;
                this.loading(true);
                const rs = await this.call({ Action: 'Resource/Upload' + this.Category, Object: {FileName:this.FileName,Link:this.Link}, Files: this.FileUpload });
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    
                } else {
                    $('#uploadFileInfo').html('');
                    this.showToast({
                        isShow: true, title: '', content: rs.Data+` file(s) are uploaded.`, type: 'success'
                    });
                    this.getAll();
                }
            }
        },
        
        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                
                if (newVal) {
                    this.start();
                }
            },
            FileName(newVal) {               
                if (this.showCreateNewFileName)                   
                    setTimeout(this.validFileName, 100);
            },
            Link() {
                setTimeout(this.validLink, 100);
            }
        }
    };
</script>
